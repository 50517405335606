import request from "@/plugins/axios";

class CompanyApi {
  getUnderCompanyURL = "/api/company/company/getUnderCompany";
  get getUnderCompanyEffectKey() {
    return `post${this.getUnderCompanyURL}`;
  }
  getUnderCompany = async (data) => {
    const response = await request({
      url: this.getUnderCompanyURL,
      method: "post",
      data: data,
    });
    return response;
  };

  getNoAgencyCompanyURL = "/api/company/company/getNoAgencyCompany";
  getNoAgencyCompanyEffectKey = `post${this.getNoAgencyCompanyURL}`;
  getNoAgencyCompany = async (data) => {
    const result = await request({
      url: this.getNoAgencyCompanyURL,
      data,
      method: "post",
    });
    return result;
  };

  registerCompanyURL = "/api/company/platform/register";
  registerCompanyEffectKey = `post${this.registerCompanyURL}`;
  registerCompany = async (data) => {
    const result = await request({
      url: this.registerCompanyURL,
      data,
      method: "post",
    });
    return result;
  };

  modifyCompanyURL = "/api/company/platform/register/modify";
  modifyCompanyEffectKey = `put${this.modifyCompanyURL}`;
  modifyCompany = async (data) => {
    const result = await request({
      url: this.modifyCompanyURL,
      data,
      method: "put",
    });
    return result;
  };
}

export const companyApi = new CompanyApi();
