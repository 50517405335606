import request from "@/plugins/axios";

class AgencyApi {
  getAgencyDetailURL = "/api/company/agency/getAgencyDetail";
  get getAgencyDetailEffectKey() {
    return `get${this.getAgencyDetailURL}`;
  }
  getAgencyDetail = async (params) => {
    const response = await request({
      url: this.getAgencyDetailURL,
      method: "get",
      params,
    });
    return response;
  };
}

export const agencyApi = new AgencyApi();
