<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">加盟商管理</span>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
        <el-button
          icon="el-icon-plus"
          type="primary"
          class="s-btn-add a-ml-24"
          @click="handlerAddProject"
        >
          加盟商
        </el-button>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form @reset="handlerRest" @search="handlerSearch">
        <le-input-icon-search
          v-model="pageParam.params.searchKey"
          label="加盟商名称"
          placeholder="请输入加盟商名称"
        />
        <!-- <le-select-local-search
          v-model="pageParam.params.type"
          label="签署状态"
          :options="optionsProject"
        /> -->
        <le-select-local-search
          v-model="pageParam.params.state"
          label="通联生效状态"
          :options="optionsProject"
        />
        <le-date-range
          label="创建时间"
          :min-date.sync="pageParam.params.startTime"
          :max-date.sync="pageParam.params.endTime"
        />
      </le-search-form>
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table :data="tableData" :highlight-current-row="true">
          <el-table-column prop="companyName" label="加盟商" min-width="150"></el-table-column>
          <el-table-column prop="status" label="通联生效状态" min-width="100">
            <template slot-scope="{ row }">
              {{ row.status === 2 ? "未生效" : "已生效" }}
            </template>
          </el-table-column>
          <el-table-column prop="stationNum" label="站点数量" min-width="100"></el-table-column>
          <el-table-column prop="deviceNum" label="设备数量" min-width="100"></el-table-column>
          <el-table-column
            prop="underCompanyNum"
            label="客户数量"
            min-width="100"
          ></el-table-column>
          <el-table-column prop="income30Day" label="30天单桩收益(元)" min-width="130">
            <template slot-scope="{ row }">
              {{ "￥" + util.numFormat(row.income30Day) }}
            </template>
          </el-table-column>
          <el-table-column prop="incomeHis" label="历史单桩收益(元)" min-width="130">
            <template slot-scope="{ row }">
              {{ "￥" + util.numFormat(row.incomeHis) }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="200">
            <template slot-scope="{ row }">
              <span>{{ row.createTimeText || "" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <img
                  src="../../assets/icon/option-detail.png"
                  class="a-wh-16 mgr20"
                  @click="toFranchiseeDetail(scope.row)"
                />
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="修改" placement="top">
                <img
                  src="../../assets/icon/edit.png"
                  class="a-wh-16 mgr20 cg-cursor-pointer"
                  @click="onEditFranchisee(scope.row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <template slot="footerLabel" slot-scope="scope">
          <div v-if="scope.val != -1" class="a-flex-rfsc">
            <span class="a-fs-12 a-c-normal">筛选出</span>
            <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
            <span class="a-fs-12 a-c-normal">个加盟商</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
    <franchisee-add
      :franchisee-modal.sync="franchiseeModal"
      :action-type="actionType"
      destroy-on-close
      width="400px"
      :form-data="currentForm"
      :label-position="'top'"
      :title="actionType === 'add' ? '新增加盟商' : '编辑加盟商'"
    />
  </div>
</template>

<script>
import util from "../../utils/util.js";
import FranchiseeAdd from "./components/FranchiseeAdd.vue";
export default {
  components: {
    FranchiseeAdd,
  },
  data() {
    return {
      util: util,
      pageParam: {
        url: this.$Config.apiUrl.getAgencyList,
        method: "post",
        params: {
          searchKey: "", //加盟商名称
          type: 0,
          startTime: "",
          endTime: "",
        },
        freshCtrl: 1,
      },
      // optionsProject: [
      //   { value: 0, label: "直属代理" },
      //   { value: 1, label: "间接代理" },
      //   { value: 2, label: "签署中" },
      //   { value: 3, label: "变更中" },
      // ], //加盟商字典
      optionsProject: [
        { value: 2, label: "未生效" },
        { value: 4, label: "已生效" },
      ], //加盟商字典
      tableData: [], //数据列表
      actionType: "add",
      franchiseeModal: false,
      currentForm: {},
    };
  },
  created() {},
  methods: {
    //获取列表
    setTableData(data) {
      this.tableData = data;
    },
    //确认筛选
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    //重置筛选表单
    handlerRest() {
      this.pageParam.params = {
        searchKey: "", //加盟商名称
        type: 0,
        startTime: "",
        endTime: "",
      };
      this.pageParam.freshCtrl++;
    },
    //添加加盟商
    handlerAddProject() {
      this.actionType = "add";
      this.franchiseeModal = true;
    },
    //跳转详情
    toFranchiseeDetail(datas) {
      if (datas.status == 2) {
        this.$router.push({
          path: "/franchisee/franchisee-contract?code=" + datas.companyId,
        });
      } else if (datas.status == 3) {
        this.$router.push({
          path: "/franchisee/franchisee-change?code=" + datas.companyId,
        });
      } else {
        this.$router.push({
          path: "/franchisee/franchisee-detail?code=" + datas.companyId,
        });
      }
    },
    onEditFranchisee(record) {
      this.actionType = "edit";
      this.franchiseeModal = true;
      this.currentForm = record;
    },
  },
};
</script>

<style lang="scss" scoped>
.mgl150 {
  margin-left: 150px;
}
</style>
