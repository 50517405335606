<template>
  <el-dialog v-bind="$attrs" :visible.sync="franchiseeModalSync" @close="onCancel">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="加盟商名称" prop="companyName">
        <el-input v-model="form.companyName" placeholder="请输入加盟商名称" maxlength="20" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号" :disabled="disabled" />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="form.password"
          :type="actionType === 'edit' ? 'password' : 'number'"
          placeholder="请输入密码"
        />
      </el-form-item>
      <el-form-item label="所属上级" prop="parentCompanyId" laebl-width="200px">
        <el-select v-model="form.parentCompanyId" :disabled="disabled" class="cg-w-300" filterable>
          <el-option
            v-for="item in companyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { agencyApi } from "@/services/apis/company/agency";
import { companyApi } from "@/services/apis/company/index";
import Validate from "@/utils/validate";
export default {
  name: "FranchiseeAdd",
  components: {},
  props: {
    franchiseeModal: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const flag = Validate.isvalidPhone(value);
      // if(!value)
      if (flag) {
        callback();
      } else {
        callback("输入正确格式的手机号");
      }
    };
    const validatePwd = (rule, value, callback) => {
      if (value.trim.length <= 20) {
        callback();
      } else {
        callback("输入正确格式的手机号");
      }
    };
    return {
      form: {
        phone: "",
        password: "123456",
      },
      companyList: [],
      rules: {
        companyName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入名称",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
            message: "请输入11位数字",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: validatePwd,
            message: "请输入密码",
          },
        ],
        parentCompanyId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择所属上级",
          },
        ],
      },
    };
  },
  computed: {
    franchiseeModalSync: {
      get() {
        return this.franchiseeModal;
      },
      set(val) {
        this.$emit("update:franchiseeModal", val);
      },
    },
    disabled() {
      return this.actionType === "edit";
    },
  },
  watch: {
    async franchiseeModal(val) {
      if (val && this.actionType === "edit") {
        console.log(this.formData, this.actionType);
        const params = {
          agencyCompanyId: this.formData.companyId,
        };
        const { password, adminMobile, parentCompanyId, companyName } =
          await agencyApi.getAgencyDetail(params);
        this.form = {
          companyName: companyName,
          password: password,
          phone: adminMobile,
          parentCompanyId: parentCompanyId,
        };
      } else {
        this.form = {
          password: "123456",
        };
      }
    },
  },
  created() {
    this.getComanyList();
  },

  methods: {
    async getComanyList() {
      const params = {
        pageNum: 1,
        pageSize: 100000,
      };
      const result = await companyApi.getUnderCompany(params);
      this.companyList = result.list;
    },
    async onConfirm() {
      await this.$refs.form.validate();
      if (this.actionType === "add") {
        const data = {
          companyName: this.form.companyName,
          phone: this.form.phone,
          password: this.form.password,
          parentCompanyId: this.form.parentCompanyId,
        };
        await companyApi.registerCompany(data);
        this.$message.success("加盟商创建成功");
      } else {
        const data = {
          companyId: this.formData.companyId,
          companyName: this.form.companyName,
          password: this.form.password,
        };
        await companyApi.modifyCompany(data);
        this.$message.success("加盟商编辑成功");
      }
      this.$emit("update:franchiseeModal", false);
    },
    onCancel() {
      if (this.actionType === "add") {
        this.form = {
          password: "123456",
        };
      }
      this.$emit("update:franchiseeModal", false);
    },
  },
};
</script>

<style scoped lang="scss"></style>
